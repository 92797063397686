export default [
  {
    header: 'Admin',
  },
  {
    title: 'Instances',
    icon: 'GridIcon',
    route: 'admin-instances-list',
  },
  {
    title: 'Report Types',
    icon: 'BarChartIcon',
    route: 'admin-reports-view-list',
  },
  {
    title: 'Reports',
    icon: 'BarChart2Icon',
    route: 'admin-reports',
  },
  {
    title: 'Users',
    icon: 'UserIcon',
    route: 'admin-users',
  },
  {
    title: 'Roles & Permissions',
    icon: 'UsersIcon',
    route: 'admin-roles-list',
  },
  {
    title: 'Programs Settings',
    icon: 'SettingsIcon',
    route: 'admin-programs-settings',
  },
  {
    title: 'All Forms',
    route: 'admin-form-folders',
    icon: 'FileTextIcon',
  },
  // {
  //   title: 'Actions Audit',
  //   icon: 'SearchIcon',
  //   route: 'admin-actions-audit',
  // },
]
